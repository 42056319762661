import {
  PersonCompanyRelationship,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { ProfilePageSection } from "@/types";
import moment from "moment/moment";
import { Relationship } from "@prisma/client";

export enum CompanyType {
  Stealth = "stealth",
  SelfEmployed = "self-employed",
  CareerBreak = "career-break",
  Company = "company",
  Education = "education",
  Investments = "investments",
  Volunteering = "volunteering",
  OtherExperience = "other-experience",
}

export type CompanyConfig = {
  name: string;
  icon: React.JSX.Element | undefined;
  type: CompanyType;
  description?: string;
  link?: string;
};

export const ExperienceLabels: { [key in string]: string } = {
  [ProfilePageSection.WorkHistory]: "Work History",
  [ProfilePageSection.Investments]: "Investments",
  [ProfilePageSection.OtherExperience]: "Other Experience",
  [ProfilePageSection.Volunteering]: "Volunteering",
  [ProfilePageSection.Education]: "Education",
};

export type GroupedExperiences = RelationshipWithEntity[];

export const formatDate = (date: string | null | undefined) => {
  if (!date || date == "Present") {
    return "Present";
  }
  if (date.length == 4) {
    return date;
  }
  const dateObj = moment(date);
  return dateObj.isValid() ? dateObj.format("MMM YYYY") : date || "";
};

export const dateStringToMoment = (date: string | null | undefined) => {
  if (date == "Present" || !date) {
    return moment();
  }
  return moment(date);
};

export function calculateDateString(
  startPosition: Partial<RelationshipWithEntity>,
  endPosition: Partial<RelationshipWithEntity>,
) {
  let startDate = startPosition.startedDate;
  let endDate = endPosition.endedDate;

  if (!startDate && !endDate) return undefined;

  if (startDate && endDate && dateStringToMoment(startDate).isAfter(dateStringToMoment(endDate))) {
    [startDate, endDate] = [endDate, startDate];
  }

  if (startDate == endDate) return formatDate(startDate);

  const duration =
    startDate ?
      dateStringToMoment(endDate).from(dateStringToMoment(startDate)).replace("in ", "")
    : undefined;

  const dates =
    startDate ? `${formatDate(startDate)} - ${formatDate(endDate)} · ${duration}` : undefined;
  return dates;
}

export const parseEmploymentTypeFromCompany = (
  relationship: Partial<Relationship> | undefined,
): CompanyType => {
  if (!relationship) return CompanyType.Company;
  const lowercaseCompany = relationship.toName?.toLowerCase().trim() ?? ""; // trim seems to be necessary for accuracy

  if (relationship.type == PersonCompanyRelationship.EducatedAt) {
    return CompanyType.Education;
  }

  if (relationship.type == PersonCompanyRelationship.InvestedIn) {
    return CompanyType.Investments;
  }

  if (relationship.type == PersonCompanyRelationship.VolunteeredAt) {
    return CompanyType.Volunteering;
  }

  const companyTypeFromKeywords = maybeGetCompanyTypeFromKeywords(
    lowercaseCompany,
    !!relationship.toId,
  );
  if (companyTypeFromKeywords) {
    return companyTypeFromKeywords;
  }

  if (relationship.type == PersonCompanyRelationship.OtherExperience) {
    return CompanyType.OtherExperience;
  }
  return CompanyType.Company;
};

export function maybeGetCompanyTypeFromKeywords(
  lowercaseCompany: string,
  urlPresent: boolean,
): CompanyType | undefined {
  const stealthKeywords = ["stealth", "stealth startup", "stealth company", "stealth mode"]; // This is a bit of a hack, we should have enum somewhere in the BE for this
  const selfEmployedKeywords = [
    "self-employed",
    "self employed",
    "freelance",
    "freelancer",
    "freelance (self employed)",
  ];
  const careerBreakKeywords = ["career break"];

  if (stealthKeywords.some((keyword) => lowercaseCompany === keyword.toLowerCase())) {
    return CompanyType.Stealth;
  }
  if (selfEmployedKeywords.some((keyword) => lowercaseCompany === keyword.toLowerCase())) {
    return CompanyType.SelfEmployed;
  }
  if (
    careerBreakKeywords.some((keyword) => lowercaseCompany === keyword.toLowerCase()) &&
    !urlPresent
  ) {
    return CompanyType.CareerBreak;
  }
}
