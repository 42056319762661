import {
  calculateDateString,
  formatDate,
  parseEmploymentTypeFromCompany,
} from "@/components/experiences/utils";
import ConditionalLink from "@/components/ui/ConditionalLink";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { classNames, smartTruncate } from "@/lib/stringUtils";
import {
  BaseRelationshipData,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { Entity, EntityType } from "@/types";
import { ReactElement, useState } from "react";
import { maybeEntityUrl } from "@/utils/entityUtils";
import { getCompanyConfig } from "@/components/experiences/CompanyConfig";

interface Props {
  firstPosition: Partial<RelationshipWithEntity>;
  lastPosition: Partial<RelationshipWithEntity>;
  useFirstPositionDescription?: boolean;
  showDates: boolean;
  editMode: boolean;
  titleButtons?: ReactElement;
}

const TRUNCATE_DESC_LENGTH = 300;

export default function CompanyHeader({
  firstPosition,
  lastPosition,
  useFirstPositionDescription,
  showDates,
  editMode,
  titleButtons,
}: Props) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const companyEntity = firstPosition.to;
  const companyConfig = getCompanyConfig({
    workExperience: firstPosition,
    companyType: parseEmploymentTypeFromCompany(firstPosition),
    companyEntity: companyEntity as Entity,
  });

  let description = companyConfig.description;
  if (useFirstPositionDescription) {
    const date = firstPosition.startedDate && formatDate(firstPosition.startedDate);
    // TODO: Originally title was included here, but investments don't have it
    const shortDescription = smartTruncate(
      (firstPosition.data as BaseRelationshipData)?.description || "",
      100,
    );
    description = [date, shortDescription].filter(Boolean).join(" · ");
  }

  // Keep in mind they usually come reverse chronologically.
  // TODO: Sort chronologically instead of relying on ordering?
  const companyDates = calculateDateString(lastPosition, firstPosition);

  const companyName = companyEntity?.name || firstPosition.toName;
  const companyLink =
    maybeEntityUrl(companyEntity) ||
    (companyName ? `/search?q=${encodeURIComponent(companyName)}` : "#");

  return (
    <>
      <div className={classNames("flex items-center")}>
        <ConditionalLink
          href={companyLink}
          data-tooltip-id="tooltip"
          data-tooltip-content={companyEntity ? `See ${companyEntity.name}'s profile` : undefined}
        >
          <EntityIconWithPlaceholder
            entity={{
              id: companyEntity?.id || firstPosition.id,
              name: companyName || firstPosition.type,
              imageUrl:
                companyEntity?.imageUrl && !companyEntity?.imageUrl?.includes("static.licdn.com") ?
                  companyEntity?.imageUrl
                : firstPosition.toImageUrl,
              fallbackImageUrl: firstPosition.toImageUrl,
              url: companyEntity?.url,
              type: EntityType.Company,
            }}
            className="h-10 w-10 mt-[2px] mr-4 border border-gray-100"
            customIcon={companyConfig.icon}
          />
        </ConditionalLink>
        <div>
          <ConditionalLink
            href={companyLink}
            className="text-base font-semibold underline mr-2 cursor-pointer"
          >
            {companyConfig.name.replace("\\'", "'")}
          </ConditionalLink>

          <span className="text-xs text-gray-400">{showDates ? companyDates : ""}</span>
        </div>
        <div className="flex-grow"></div>
        <div className="flex-shrink-0">{titleButtons}</div>
      </div>
      {description && (
        <div className=" sm:-mt-2">
          <div className="text-sm sm:ml-14">
            {editMode || showFullDescription ?
              description
            : smartTruncate(description, TRUNCATE_DESC_LENGTH)}
            {description.length > TRUNCATE_DESC_LENGTH && !showFullDescription && (
              <span
                className="ml-2 font-bold cursor-pointer"
                onClick={() => setShowFullDescription(true)}
              >
                Read more
              </span>
            )}
          </div>
          <div className="h-[8px]" />
        </div>
      )}
    </>
  );
}
