import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

/* eslint-disable @next/next/no-img-element */
import API from "@/client/api";
import { shouldShowCompanyWebsite } from "@/components/companies/CompanySubtitle";
import Button from "@/components/ui/Button";
import Loader from "@/components/ui/Loader";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import PopoverMenu from "@/components/ui/PopoverMenu";
import Spinner from "@/components/ui/Spinner";
import { ageFromBirthYear, formatNumber, pluralize, prettyUrl } from "@/lib/stringUtils";
import { CompanyCardData, Entity, EntityType, PersonCardData } from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { capitalize } from "lodash";
import Link from "next/link";
import useSWRImmutable from "swr/immutable";
import { entityStore, MutualConnectionsData } from "@/stores/entityStore";
import { useStore } from "@nanostores/react";

export type EntityForCard = Partial<Pick<Entity, "id" | "type" | "imageUrl" | "url" | "name">> &
  Pick<Entity, "type">;

export default function EntityCard({
  border = true,
  entity: originalEntity,
  email,
  contextMenu,
  loading,
  entityIconClassName,
  entityDetailsClassName,
  onClick,
}: {
  entity: EntityForCard;
  // Email will be shown for EntityType.Person if provided, otherwise
  // more facts will take up the space.
  email?: string;
  loading?: boolean;
  contextMenu?: React.JSX.Element | boolean;
  border?: boolean;
  entityIconClassName?: string;
  entityDetailsClassName?: string;
  onClick?: (e: React.MouseEvent) => void;
}) {
  const [entity, setEntity] = useState(originalEntity);

  const {
    data: cardData,
    error,
    isLoading: factsLoading,
  } = useSWRImmutable(
    originalEntity.id ? ["getCardData", originalEntity.id] : null,
    ([command, entityId]) => {
      // Currently SWR is only used in this component. If we want to use it in more places,
      // we will need to have a more generic way to call the API.
      if (command === "getCardData") return API.getCardData({ entityId });
    },
  );

  useEffect(() => {
    if (cardData) {
      setEntity({ ...originalEntity, ...cardData.entity } as EntityForCard);
    }
  }, [cardData, originalEntity]);

  const personCardData =
    entity.type === EntityType.Person ? (cardData as PersonCardData) : undefined;
  const companyCardData =
    entity.type === EntityType.Company ? (cardData as CompanyCardData) : undefined;

  const buttonLabel =
    loading ?
      <Loader className="" />
    : <EllipsisVerticalIcon className="w-4 text-gray-400 ml-auto " />;

  const TwoFacts = ({ fact1, fact2 }: { fact1?: string; fact2?: string }) => {
    // In a flex context this means: starting with 0px grow this element as much as other elements,
    // but never make it larger than necessary. This means that if two facts are ellipsised, they
    // will be equal size, but if only one is, the other one will take as little space
    // as possible.
    const factClass =
      "overflow-hidden text-ellipsis text-nowrap flex-shrink-1 grow basis-0 max-w-[fit-content]";

    if (!fact1 && !fact2) return null;
    return (
      <div className="flex flex-row justify-start">
        {fact1 && <div className={factClass}>{fact1}</div>}
        {fact1 && fact2 && <div className="flex-shrink-0">&nbsp;•&nbsp;</div>}
        {fact2 && <div className={factClass}>{fact2}</div>}
      </div>
    );
  };

  return (
    <Link
      href={entity.id ? entityUrl(entity) : entity.url || "#"}
      onClick={(e: React.MouseEvent) => {
        onClick?.(e);
      }}
    >
      <div className="h-full w-full">
        <div
          className={twMerge(
            "rounded-md relative border-gray-100 bg-white w-full h-full p-2",
            border && "border",
            `cursor-pointer hover:bg-blue-100`,
          )}
        >
          {contextMenu && (
            <PopoverMenu
              buttonClass="absolute top-2 right-2"
              buttonLabel={buttonLabel}
              popperOptions={{ placement: "bottom-end" }}
              keepOpenOnClick
            >
              {contextMenu}
            </PopoverMenu>
          )}
          {!contextMenu && loading && <div className="absolute top-2 right-2">{buttonLabel}</div>}

          <div className="flex items-center gap-4 max-h-full">
            {!loading && (
              <EntityIconWithPlaceholder
                className={twMerge(
                  "h-[80px] w-[80px] shadow-inner overflow-hidden",
                  entityIconClassName,
                )}
                imageClassName="w-full h-full"
                entity={entity}
              />
            )}

            <div className="flex-1 flex flex-col overflow-hidden text-xs">
              <div
                className={twMerge(
                  "overflow-hidden line-clamp-1 text-ellipsis mb-1 font-semibold pr-4",
                  entityDetailsClassName,
                )}
              >
                {entity.name}
              </div>
              {email && email !== entity.name && (
                <div
                  className={twMerge(
                    "text-gray-500 line-clamp-1 text-ellipsis overflow-hidden mb-1",
                  )}
                >
                  {email}
                </div>
              )}
              {email && !entity.id && !loading && (
                <Button className="justify-center mt-2">Add profile</Button>
              )}
              {factsLoading && <Spinner />}
              {entity.type === EntityType.Person && (
                <TwoFacts
                  fact1={personCardData?.currentPosition}
                  fact2={personCardData?.currentCompany}
                />
              )}
              {entity.type === EntityType.Person && (
                <TwoFacts
                  fact1={personCardData?.location}
                  fact2={
                    personCardData?.directlyConnected ? "Connected"
                    : !!personCardData?.mutuals ?
                      pluralize(personCardData?.mutuals || 0, "Mutual")
                    : ""
                  }
                />
              )}
              {entity.type === EntityType.Person && !email && (
                <TwoFacts
                  fact1={capitalize(ageFromBirthYear(personCardData?.birthYear) || "")}
                  fact2={personCardData?.pronouns}
                />
              )}
              {entity.type === EntityType.Company && (
                <TwoFacts
                  fact1={
                    companyCardData?.mutuals ?
                      pluralize(companyCardData?.mutuals || 0, "Mutual")
                    : ""
                  }
                  fact2={
                    companyCardData?.employees ?
                      formatNumber(companyCardData?.employees || 0) + " People"
                    : ""
                  }
                />
              )}
              {entity.type === EntityType.Company && (
                <TwoFacts
                  fact1={companyCardData?.location}
                  fact2={companyCardData?.founded ? `Founded ${companyCardData?.founded}` : ""}
                />
              )}
              {entity.type === EntityType.Company &&
                companyCardData?.website &&
                shouldShowCompanyWebsite(companyCardData?.website) && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(companyCardData?.website, "_blank");
                    }}
                    className="flex flex-row text-gray-500 hover:underline mt-2"
                  >
                    <div className="overflow-hidden text-ellipsis text-nowrap">
                      {prettyUrl(companyCardData?.website)}
                    </div>
                    <ArrowTopRightOnSquareIcon className="h-3 w-3 self-center ml-1 flex-shrink-0" />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export const makeEntityCardFromMutualConnection = (
  connection: MutualConnectionsData,
): EntityForCard => {
  const makeEntityUrl = (connection: MutualConnectionsData) => {
    if (!connection) {
      return undefined;
    }
    return connection.entity ?
        entityUrl(connection.entity)
      : `/profile?url=${encodeURIComponent(connection.data?.linkedinUrl || "")}`;
  };

  const entity: Partial<Entity> = connection.entity || {};

  return {
    ...entity,
    type: EntityType.Person,
    name: entity.name || connection?.data?.name || "",
    url: entity.url || makeEntityUrl(connection),
    imageUrl:
      entity.imageUrl?.startsWith("http") ? entity.imageUrl
      : connection?.data?.profileImage?.startsWith("http") ? connection?.data?.profileImage
      : undefined,
  } as EntityForCard;
};
