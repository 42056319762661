import ExperienceSection from "@/components/experiences/ExperienceSection";
import { ExperienceLabels, GroupedExperiences } from "@/components/experiences/utils";
import { RelationshipWithEntity } from "@/models/relationship/relationshipTypes";
import { entityStore } from "@/stores/entityStore";
import { profilePageUiStore } from "@/stores/profilePageUiStore";
import { findAttribute } from "@/stores/utils";
import { AttributeType, PipelineRunStatus, ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";

interface Props {
  status: PipelineRunStatus;
}

function groupExperiences(experiences: RelationshipWithEntity[]) {
  const result: GroupedExperiences[] = [];
  let previousToId = undefined;
  let previousToName = undefined;
  let currentGroupedExperiences = [];
  for (const experience of experiences) {
    // We are creating a new group if
    if (
      // We know the current group ID and it's different than current iteration
      (previousToId && experience.toId !== previousToId) ||
      // We do not know the current group ID, but we know the name and it differs
      (!previousToId && previousToName && experience.toName !== previousToName) ||
      // We know nothing - safer to not group
      (!previousToId && !previousToName && !experience.toId && !experience.toName)
    ) {
      result.push(currentGroupedExperiences);
      currentGroupedExperiences = [];
    }
    currentGroupedExperiences.push(experience);
    previousToId = experience.toId;
    previousToName = experience.toName;
  }
  if (currentGroupedExperiences.length > 0) result.push(currentGroupedExperiences);
  return result;
}

export default function PastExperiences({ status }: Props) {
  const relationshipSections = useStore(entityStore.relationshipSections);
  const addingProfileSections = useStore(profilePageUiStore.addingProfileSections);

  const workHistory = relationshipSections[ProfilePageSection.WorkHistory];
  const hasLegacyData =
    workHistory?.length == 0 &&
    (findAttribute(entityStore.attributes.get(), AttributeType.LinkedinProfile)?.value.experience
      .length || 0) > 0;

  if (hasLegacyData) {
    return (
      <div className="text-center">
        {status == PipelineRunStatus.IN_PROGRESS ? "Loading..." : "No recent relationship data yet"}
      </div>
    );
  }

  return (
    <>
      {Object.keys(ExperienceLabels).map((section: string) => {
        const experiences = relationshipSections[section as ProfilePageSection] || [];
        if (
          !experiences.length &&
          section !== ProfilePageSection.WorkHistory &&
          !addingProfileSections[section as ProfilePageSection]
        )
          return null;
        const allGroupedExperiences = groupExperiences(experiences);
        return (
          <ExperienceSection
            key={section}
            section={section as ProfilePageSection}
            allGroupedExperiences={allGroupedExperiences}
          />
        );
      })}
    </>
  );
}
