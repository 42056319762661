import moment from "moment";
import { Fragment, ReactNode } from "react";

import EditableFact from "@/components/entities/EditableFact";
import { prettyUrl } from "@/lib/utils";
import { CompanyFact, PersonFact } from "@/models/facts/facts.types";
import { entityStore } from "@/stores/entityStore";
import { findAttribute } from "@/stores/utils";
import { Attribute, AttributeType, EntityType } from "@/types";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";

type Props = {
  entity: Entity;
  attributes: Attribute[];
  skipLastUpdated?: boolean;
};

export function shouldShowCompanyWebsite(website?: string) {
  return website && !website.includes("linkedin.com/") && !website.includes("distill://");
}

export default function CompanySubtitle(props: Props) {
  const facts = useStore(entityStore.facts);
  const canEditAttrs = useStore(entityStore.canEdit);
  const { entity, attributes, skipLastUpdated } = props;

  const subtitles: (ReactNode | string)[] = [];
  const linkedinProfile = findAttribute(attributes, AttributeType.LinkedinCompanyProfile);

  const website = facts[CompanyFact.Website]?.value || entity.url;
  if (shouldShowCompanyWebsite(website)) {
    subtitles.push(
      <a href={website} className="text-blue-500 hover:text-blue-700">
        {prettyUrl(website)}
      </a>,
    );
  }

  if (facts[CompanyFact.CompanyType]) {
    subtitles.push(facts[CompanyFact.CompanyType]?.value);
  } else if (linkedinProfile?.value.companyType) {
    subtitles.push(linkedinProfile.value.companyType);
  }

  const location =
    facts[CompanyFact.Headquarters]?.value || linkedinProfile?.value.profile.location;
  if (location) {
    subtitles.push(
      <EditableFact
        factType={CompanyFact.Headquarters}
        canEdit={canEditAttrs}
        onEdit={() =>
          entityStore.showFactEditModal.set({
            type: CompanyFact.Headquarters,
            currentValue: location,
          })
        }
      >
        {location}
      </EditableFact>,
    );
  }

  if (facts[CompanyFact.Industry]) {
    subtitles.push(facts[CompanyFact.Industry]?.value);
  } else if (linkedinProfile?.value.profile.industry) {
    subtitles.push(linkedinProfile.value.profile.industry);
  }

  if (entity.generatedAt && !skipLastUpdated)
    subtitles.push("Last Updated: " + moment(entity.generatedAt).fromNow());

  return (
    <div className="text-sm text-gray-400 inline">
      {subtitles.filter(Boolean).map((subtitle, idx) => (
        <Fragment key={idx}>
          {subtitle} {idx != subtitles.length - 1 ? " • " : ""}
        </Fragment>
      ))}
    </div>
  );
}
