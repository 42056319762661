import Loader from "@/components/ui/Loader";
import PopoverMenu, { PopoverOption } from "@/components/ui/PopoverMenu";
import { useDevTools } from "@/hooks/useUIStore";
import errorTracker from "@/lib/errorTracker";
import { CompanyFact } from "@/models/facts/facts.types";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { Entity, EntityType, PipelineRunStatus, UserWithMeta } from "@/types";
import { entityIsUser } from "@/utils/entityUtils";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { User } from "@prisma/client";
import moment from "moment/moment";
import { twMerge } from "tailwind-merge";

const UpdateButton = ({
  update,
  disabled,
  inProgress,
}: {
  update: () => Promise<void>;
  disabled?: boolean;
  inProgress?: boolean;
}) => {
  const disabledProps = (disabled || inProgress) && {
    "data-tooltip-content": disabled ? "Log in to update profile" : undefined,
    "data-tooltip-id": "tooltip",
    disabled: true,
  };

  return (
    <div className="text-gray-400 flex-row items-start" onClick={update}>
      <div className="flex flex-row gap-1 flex-wrap items-center">
        <button
          className={twMerge(
            "sm:ml-1 text-blue-600 font-semibold",
            disabledProps && "text-gray-400 cursor-not-allowed",
          )}
          {...disabledProps}
        >
          {inProgress ? "Refreshing..." : "Update now"}
        </button>
      </div>
    </div>
  );
};

const ProfilePopoverMenu = ({
  entity,
  isRegenerating,
  status,
  initiallyOpen,
}: {
  entity: Entity;
  isRegenerating: boolean;
  status: PipelineRunStatus;
  initiallyOpen?: boolean;
}) => {
  const user = useStore(uiStore.user);
  const canEdit = useStore(entityStore.canEdit);
  const isAdmin = useDevTools();
  const slugPrefix = entity.slug?.split("/")[1];
  const slugWithoutPrefix =
    entity.slug && slugPrefix ?
      decodeURIComponent(entity.slug.substring(slugPrefix.length + 2))
    : "";

  const yesterday = moment().subtract(1, "d");
  const isOneDayOld = moment(entity.generatedAt).isBefore(yesterday);

  const showUpdateButton = !isRegenerating && isOneDayOld;
  const updateButton =
    showUpdateButton ?
      <UpdateButton
        update={entityStore.triggerEntityRefresh}
        disabled={!user}
        inProgress={status == PipelineRunStatus.IN_PROGRESS}
      />
    : null;

  if (!canEdit && !showUpdateButton) return null;

  return (
    <PopoverMenu
      buttonLabel={<EllipsisVerticalIcon className="w-6 text-gray-400 ml-auto " />}
      popperOptions={{ placement: "bottom-end" }}
      keepOpenOnClick
      initiallyOpen={initiallyOpen}
    >
      <div className="px-4 py-2">
        <UpdatedAt generatedAt={entity.generatedAt} isRegenerating={isRegenerating} />
      </div>
      {showUpdateButton && <PopoverOption>{updateButton}</PopoverOption>}
      {canEdit && (
        <PopoverOption>
          <div
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => {
              uiStore.showInputModal.set({
                title: "Edit Name",
                type: "edit",
                fields: [
                  {
                    currentValue: entity.name,
                  },
                ],
                onSubmit: async (values: string[]) => {
                  try {
                    if (!user) return;

                    const originalEntityName = entity.name;
                    const originalUserName = user.name || "";

                    // Perform both updates
                    await Promise.all([
                      entityStore.updateEntityFields(entity.id, { name: values[0] }),
                      updateNameIfUserIsEntity(entity, user, values[0]),
                    ]).catch(async (error: unknown) => {
                      // If either update fails, revert both changes
                      await Promise.all([
                        entityStore.updateEntityFields(entity.id, { name: originalEntityName }),
                        updateNameIfUserIsEntity(entity, user, originalUserName),
                      ]);
                      throw error;
                    });
                  } catch (error) {
                    errorTracker.sendError(error, { source: "update-name" });
                  }
                  uiStore.reloadPage();
                },
              });
            }}
          >
            Edit Name
          </div>
        </PopoverOption>
      )}
      {canEdit && (
        <PopoverOption>
          <div
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => {
              entityStore.showFactEditModal.set({
                type: CompanyFact.Website,
                currentValue: entityStore.facts.get()[CompanyFact.Website]?.value || "",
              });
            }}
          >
            Edit Website URL
          </div>
        </PopoverOption>
      )}
      {isAdmin && (
        <>
          <PopoverOption>ADMIN STUFF</PopoverOption>
          <PopoverOption
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => {
              uiStore.showInputModal.set({
                title: "Edit Slug URL",
                type: "edit",
                fields: [
                  {
                    currentValue: slugWithoutPrefix || "",
                  },
                ],
                onSubmit: async (values: string[]) => {
                  const fullSlug = `/${slugPrefix}/${values[0]}`;
                  await entityStore.updateEntityFields(entity.id, { slug: fullSlug });
                  uiStore.routeTo(fullSlug);
                },
              });
            }}
          >
            Edit Slug URL
          </PopoverOption>
          <PopoverOption
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => {
              uiStore.showInputModal.set({
                title: "Edit canonical url URL",
                type: "edit",
                fields: [
                  {
                    currentValue: entity.url,
                  },
                ],
                onSubmit: async (values: string[]) => {
                  await entityStore.updateEntityFields(entity.id, { url: values[0] });
                  uiStore.reloadPage();
                },
              });
            }}
          >
            Edit Canonical URL
          </PopoverOption>
          <PopoverOption
            className="sm:ml-1 text-blue-600 font-semibold"
            onClick={() => entityStore.deleteHighlights()}
          >
            Delete Highlights
          </PopoverOption>
        </>
      )}
    </PopoverMenu>
  );
};

const UpdatedAt = ({
  generatedAt,
  isRegenerating,
}: {
  generatedAt?: Date | null;
  isRegenerating: boolean;
}) => {
  const user = useStore(uiStore.user);
  if (!user || !generatedAt) {
    return null;
  }
  const age = moment(generatedAt).fromNow();
  return (
    <div className="hidden sm:flex flex-row gap-1 flex-wrap sm:justify-end items-center">
      <p>Updated {age}</p>
      {isRegenerating && (
        <>
          <Loader className="mx-2" /> <p>Updating...</p>
        </>
      )}
    </div>
  );
};

async function updateNameIfUserIsEntity(entity: Entity, user: User | UserWithMeta, name: string) {
  if (entityIsUser(entity, user)) {
    return uiStore.updateUser({ name: name });
  }
  return null;
}

export default ProfilePopoverMenu;
