import API from "@/client/api";
import ChangeCompanyModal from "@/components/experiences/ChangeCompanyModal";
import GroupedExperienceRow from "@/components/experiences/GroupedExperienceRow";
import { ExperienceLabels, GroupedExperiences } from "@/components/experiences/utils";
import ProfileBubble from "@/components/sections/ProfileBubble";
import ExpandoList from "@/components/ui/ExpandoList";
import Spinner from "@/components/ui/Spinner";
import { entityStore } from "@/stores/entityStore";
import { Entity, EntityType, ProfilePageSection, sectionToRelationshipType } from "@/types";
import {
  PersonCompanyRelationship,
  RelationshipWithEntity,
} from "@@/src/models/relationship/relationshipTypes";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { twJoin } from "tailwind-merge";
import { uiStore } from "@/stores/uiStore";
import { profilePageUiStore } from "@/stores/profilePageUiStore";

interface Props {
  section: ProfilePageSection;
  allGroupedExperiences: GroupedExperiences[];
}

const isGridView = (section: string) => section == (ProfilePageSection.Investments as string);

export default function ExperienceSection({ section, allGroupedExperiences }: Props) {
  const addingProfileSections = useStore(profilePageUiStore.addingProfileSections);
  const entity = useStore(entityStore.entity);
  const [addingNewCompany, setAddingNewCompany] = useState(!!addingProfileSections[section]);
  const [loadingNewCompany, setLoadingNewCompany] = useState(false);
  const canEdit = useStore(entityStore.canEdit);

  // ExpandoList is difficult to add a special element into. We are treating an empty
  // list on first position as special case of adding new company experience.
  // make it trigger if addMode is set to true
  const allGroupedExperiencesToShow = (
    (addingNewCompany ? [[]] : []) as GroupedExperiences[]
  ).concat(allGroupedExperiences);

  const skipExperienceSection =
    entity.type === EntityType.Person &&
    allGroupedExperiencesToShow.length === 0 &&
    !entity.url.includes("linkedin.com") &&
    !addingProfileSections[section];

  if (skipExperienceSection) {
    return null;
  }

  return (
    <div className="group">
      <ProfileBubble
        title={ExperienceLabels[section]}
        key={section}
        section={section}
        className="flex flex-col"
        titleButtons={
          <>
            {canEdit && !addingNewCompany && (
              <>
                <PlusIcon
                  className="flex-shrink-0 cursor-pointer hover:text-brand-600 h-6 w-6 text-gray-500 group-hover:block hidden"
                  onClick={() => {
                    setAddingNewCompany(true);
                  }}
                />
              </>
            )}
          </>
        }
      >
        <>
          {allGroupedExperiencesToShow.length === 0 && (
            <div className="text-center text-gray-500">No work history listed yet</div>
          )}
          {loadingNewCompany && (
            <div className="my-2 w-full flex flex-row justify-center">
              <Spinner />
            </div>
          )}
          {allGroupedExperiencesToShow.length > 0 && (
            <ExpandoList
              items={allGroupedExperiencesToShow}
              limit={!isGridView(section) ? 5 : 6}
              seeMoreClassName="pt-4 text-center"
              className={twJoin(
                "list-inside text-gray-700 gap-4 flex flex-col",
                isGridView(section) ? "sm:grid sm:grid-cols-2" : "",
              )}
              divideExpander
              renderItem={(groupedExperiences: GroupedExperiences, index) => (
                <div
                  key={
                    (groupedExperiences[0]?.toId || groupedExperiences[0]?.toName || "empty") +
                    index.toString()
                  }
                >
                  {index !== 0 && !isGridView(section) && <hr className="mb-2" />}
                  <GroupedExperienceRow
                    groupedExperiences={groupedExperiences}
                    section={section}
                    addMode={index === 0 && addingNewCompany}
                    onCancelAddMode={() => {
                      setAddingNewCompany(false);
                      if (addingProfileSections[section]) {
                        profilePageUiStore.endAddingProfileSection(section);
                      }
                    }}
                  />
                </div>
              )}
            />
          )}
        </>
      </ProfileBubble>
    </div>
  );
}
