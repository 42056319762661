import API from "@/client/api";
import ChangeCompanyModal from "@/components/experiences/ChangeCompanyModal";
import CompanyHeader from "@/components/experiences/CompanyHeader";
import EditExperienceRow from "@/components/experiences/EditExperienceRow";
import ExperienceDetailsRow from "@/components/experiences/ExperienceDetailsRow";
import { GroupedExperiences } from "@/components/experiences/utils";
import useSubmitButton from "@/components/hooks/useSubmitButton";
import ComboBox, {
  ComboBoxItemType,
  makeComboBoxItemsFromEnum,
} from "@/components/inputs/ComboBox";
import TextField from "@/components/inputs/TextField";
import WithLabel from "@/components/inputs/WithLabel";
import Button, { ButtonVariant } from "@/components/ui/Button";
import Spinner from "@/components/ui/Spinner";
import { prettyError } from "@/lib/miscUtils";
import {
  PersonCompanyRelationshipLabels,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { Entity, ProfilePageSection, sectionToRelationshipType } from "@/types";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface Props {
  groupedExperiences: GroupedExperiences;
  section: ProfilePageSection;
  // This is used to add a whole grouped experience (e.g. new company with
  // multiple positions).
  addMode?: boolean;
  onCancelAddMode?: () => void;
  // For testability
  initialEditMode?: boolean;
}

function createEmptyPosition(position: RelationshipWithEntity) {
  return {
    fromId: position.fromId,
    toId: position.toId,
    toName: position.toName,
    type: position.type,
    to: position.to,
  };
}

export default function GroupedExperienceRow({
  groupedExperiences,
  section,
  initialEditMode,
  addMode,
  onCancelAddMode,
}: Props) {
  const canEdit = useStore(entityStore.canEdit);
  const entity = useStore(entityStore.entity);

  const [editMode, setEditMode] = useState(initialEditMode || addMode || false);
  const [hovering, setHovering] = useState(false);
  const [showChangeCompanyModal, setShowChangeCompanyModal] = useState(addMode || false);
  const [addingNewPosition, setAddingNewPosition] = useState(addMode || false);
  const [addModeCompany, setAddModeCompany] = useState<
    { id?: string; name?: string; company?: Entity } | undefined
  >();
  const [loadingAddModeCompany, setLoadingAddModeCompany] = useState(false);

  const { SubmitButton, setSubmitting } = useSubmitButton();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!editMode) {
      setAddingNewPosition(false);
    }
  }, [editMode]);

  // If the source data changes it means that the user has added a new position
  // TODO: It might also mean they updated a position, in which case we might not
  // want to reset the addingNewPosition state.
  useEffect(() => {
    if (!addMode) {
      setAddingNewPosition(false);
    }
  }, [groupedExperiences, addMode]);

  useEffect(() => {
    if (!showChangeCompanyModal && addMode && !addModeCompany) {
      onCancelAddMode?.();
    }
  }, [showChangeCompanyModal]);

  if (groupedExperiences.length === 0 && !addMode) {
    return null;
  }

  const firstExperience = groupedExperiences[0];
  const lastExperience =
    groupedExperiences.length > 0 ? groupedExperiences[groupedExperiences.length - 1] : undefined;
  const addModeFakePosition =
    addModeCompany ?
      {
        fromId: entity.id,
        toId: addModeCompany.id,
        toName: addModeCompany.name,
        type: sectionToRelationshipType(section),
        to: addModeCompany.company,
      }
    : groupedExperiences.length > 0 ? createEmptyPosition(groupedExperiences[0])
    : undefined;

  const showOnlyCompanyHeader = section === ProfilePageSection.Investments;

  const relationshipTypes: ComboBoxItemType[] = [
    //{ id: "", name: UNSPECIFIED },
    ...makeComboBoxItemsFromEnum(PersonCompanyRelationshipLabels),
  ];

  return (
    <div
      className="group flex flex-col flex-1 gap-2 static"
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <ChangeCompanyModal
        open={showChangeCompanyModal}
        setOpen={(open) => {
          setShowChangeCompanyModal(open);
        }}
        selectCompany={async ({ id, name, company }) => {
          if (addMode) {
            setAddModeCompany({ id, name, company });
          } else if (firstExperience) {
            await entityStore.batchUpdateRelationships(
              {
                toId: firstExperience.toId || undefined,
                toName: firstExperience.toId ? undefined : firstExperience.toName || undefined,
                entityId: firstExperience.fromId,
              },
              {
                toId: id || null,
                toName: id ? null : name,
                to: company || null,
              },
            );
          }
          setShowChangeCompanyModal(false);
        }}
      />
      {loadingAddModeCompany && <Spinner />}
      {((firstExperience && lastExperience) || addModeFakePosition) && (
        <CompanyHeader
          firstPosition={
            (firstExperience || addModeFakePosition) as Partial<RelationshipWithEntity>
          }
          lastPosition={(lastExperience || addModeFakePosition) as Partial<RelationshipWithEntity>}
          showDates={section !== ProfilePageSection.Investments}
          useFirstPositionDescription={section === ProfilePageSection.Investments}
          editMode={editMode}
          titleButtons={
            <>
              {canEdit && hovering && !editMode && (
                <>
                  <PencilSquareIcon
                    className="flex-shrink-0 cursor-pointer hover:text-brand-600 ml-2 h-5 w-5 text-gray-500"
                    onClick={() => setEditMode(true)}
                  />
                </>
              )}
              {canEdit && editMode && !addMode && (
                <>
                  <Button variant={ButtonVariant.SmallSecondary} onClick={() => setEditMode(false)}>
                    Finish editing
                  </Button>
                </>
              )}
            </>
          }
        />
      )}
      {editMode && !addMode && (
        <div className="flex flex-row gap-2 pl-14">
          <Button
            variant={ButtonVariant.SmallSecondary}
            onClick={() => {
              setShowChangeCompanyModal(true);
            }}
          >
            Change organization
          </Button>
          <Button
            variant={ButtonVariant.SmallSecondary}
            onClick={() => {
              uiStore.showConfirmModal.set({
                type: "danger",
                title:
                  "Delete experiences at " +
                  (firstExperience.to?.name || firstExperience.toName || "") +
                  "?",
                subtitle:
                  "Delete all experiences with the organization? The action is not reversible.",
                onClick: async () => {
                  try {
                    await entityStore.batchDeleteRelationships({
                      toId: firstExperience.toId || undefined,
                      toName:
                        firstExperience.toId ? undefined : firstExperience.toName || undefined,
                      entityId: firstExperience.fromId,
                    });
                  } catch (e) {
                    toast.error("Error deleting relationships: " + prettyError(e));
                  }
                },
              });
            }}
          >
            Delete
          </Button>
          <ComboBox
            name="type"
            items={relationshipTypes}
            placeholder={"Reclassify as..."}
            value={undefined}
            onSelect={async (typeOrArray) => {
              const type = Array.isArray(typeOrArray) ? typeOrArray[0] : typeOrArray;
              try {
                entityStore.batchUpdateRelationships(
                  {
                    toId: firstExperience.toId || undefined,
                    toName: firstExperience.toId ? undefined : firstExperience.toName || undefined,
                    entityId: firstExperience.fromId,
                  },
                  {
                    type: type.id,
                  },
                );
              } catch (e) {
                setError(prettyError(e));
              }
            }}
          />
        </div>
      )}
      {!showOnlyCompanyHeader && !addMode && (
        <>
          {groupedExperiences.map((position, index) => (
            <ExperienceDetailsRow
              key={index}
              position={position}
              showDates={groupedExperiences.length != 1}
              editMode={editMode}
            />
          ))}
        </>
      )}
      {!showOnlyCompanyHeader && editMode && !addingNewPosition && !addMode && (
        <>
          <div className="flex justify-start ml-14">
            <Button
              variant={ButtonVariant.SmallSecondary}
              onClick={() => setAddingNewPosition(true)}
            >
              Add another position
            </Button>
          </div>
        </>
      )}
      {!showOnlyCompanyHeader && editMode && addingNewPosition && addModeFakePosition && (
        <>
          <EditExperienceRow
            onFinishEdit={() => {
              setAddingNewPosition(false);
              if (addMode) {
                onCancelAddMode?.();
              }
            }}
            relationship={addModeFakePosition}
          />
        </>
      )}
      {editMode && section === ProfilePageSection.Investments && (
        <>
          <form
            onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              setSubmitting(true);
              const investment = groupedExperiences[0];
              const form = e.target as HTMLFormElement;
              const formData = new FormData(form);
              const investmentDate = formData.get("investmentDate") as string | null;
              try {
                if (investment && investment?.id) {
                  await entityStore.updateRelationship({
                    ...(groupedExperiences[0] as { id: string }),
                    startedDate: investmentDate,
                  });
                  setEditMode(false);
                } else {
                  if (addMode && addModeFakePosition) {
                    await entityStore.createRelationship({
                      ...addModeFakePosition,
                      startedDate: investmentDate,
                      data: {},
                    });
                    onCancelAddMode?.();
                  }
                }
              } catch (e) {
                setError(prettyError(e));
              } finally {
                setSubmitting(false);
              }
            }}
          >
            <WithLabel label={"Investment date"}>
              <TextField
                name="investmentDate"
                placeholder="YYYY-MM"
                defaultValue={groupedExperiences[0]?.startedDate || ""}
              />
            </WithLabel>
            {error && <div className="text-red-500 mt-2">{error}</div>}
            <SubmitButton className="mt-2">Save</SubmitButton>
          </form>
        </>
      )}
    </div>
  );
}
