import SourceTooltip, { USER_FEEDBACK_TOOLTIP_TITLE } from "@/components/entities/SourceTooltip";
import SourceTooltipLink, {
  getReferenceDataToShowFromCitationId,
} from "@/components/entities/SourceTooltipLink";
import { ClearButton } from "@/components/ui/Button";
import ExpandoList from "@/components/ui/ExpandoList";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { SnapshotHighlight } from "@/types";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { useSession } from "next-auth/react";
import { AnchorHTMLAttributes, HTMLAttributes } from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

export default function EntityHighlights() {
  const highlights = useStore(entityStore.highlights);
  const session = useSession();

  if (!highlights?.length) return null;
  return (
    <>
      <hr className="my-2" />
      <div className="flex items-center gap-1">
        <h2 className="text-gray-400 text-base">Highlights</h2>
        {session.status === "authenticated" && (
          <ClearButton
            className="absolute right-2 text-gray-300 hover:text-gray-500"
            onClick={entityStore.updateHighlightsFromFeedback}
          >
            <PencilIcon className="h-4 w-4" />
          </ClearButton>
        )}
      </div>
      <div className="overview markdown">
        <Bullets bullets={highlights} />
      </div>
    </>
  );
}

function Bullets({ bullets }: { bullets: SnapshotHighlight[] }) {
  const snapshot = useStore(entityStore.snapshot);
  if (!bullets) return null;

  return (
    <div className="flex flex-col gap-2">
      <SourceTooltip />
      <ExpandoList
        items={bullets}
        seeMoreClassName="border-t mt-4 pt-4"
        renderItem={(bullet, i) => (
          <div key={i} className="flex flex-row">
            <span className="hidden sm:inline font-bold text-xl text-gray-300 ml-4 mr-8">•</span>
            <ReactMarkdown
              remarkPlugins={[remarkBreaks]}
              components={{
                strong(props: HTMLAttributes<HTMLBaseElement>) {
                  return <span className="font-medium">{props.children}</span>;
                },
                a(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
                  const { title, ...rest } = props;
                  return <SourceTooltipLink title={title} {...rest} />;
                },
              }}
            >
              {bullet.description +
                (bullet.citationIds
                  ?.map((citationId, j) => {
                    const referenceData = getReferenceDataToShowFromCitationId(
                      snapshot,
                      citationId,
                    );
                    if (!referenceData) return "";
                    const { idx, url, citationUrl } = referenceData;
                    return `[${idx}](${citationUrl} '${url}')`;
                  })
                  .join(" ") || "") +
                (bullet.userSources?.length ? `[u](# '${USER_FEEDBACK_TOOLTIP_TITLE}')` : "")}
            </ReactMarkdown>
          </div>
        )}
      />
    </div>
  );
}
