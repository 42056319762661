/* eslint-disable @next/next/no-img-element */
import CompanySubtitle from "@/components/companies/CompanySubtitle";
import ProfilePopoverMenu from "@/components/entities/ProfilePopoverMenu";
import { SectionSummary } from "@/components/entities/ProfileSection";
import SourceTooltip from "@/components/entities/SourceTooltip";
import SourceTooltipLink, {
  getReferenceDataToShowFromCitationId,
} from "@/components/entities/SourceTooltipLink";
import EntityImageEditModal from "@/components/modals/EntityImageEditModal";
import PersonSubtitle from "@/components/people/PersonSubtitle";
import EntityHighlights from "@/components/sections/EntityHighlights";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoList from "@/components/ui/ExpandoList";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { CompanyFact } from "@/models/facts/facts.types";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { EntityType, NO_DESCRIPTION, PipelineRunStatus, ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import { AnchorHTMLAttributes, HTMLAttributes, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { twJoin } from "tailwind-merge";

export default withErrorBoundary(function EntityV3Header({
  status,
}: {
  status: PipelineRunStatus;
}) {
  const entity = useStore(entityStore.entity);
  const updateQueue = useStore(entityStore.updateEntityQueue);
  const attributes = useStore(entityStore.attributes);
  const facts = useStore(entityStore.facts);
  const companyBrandedBlurb = facts[CompanyFact.BrandedBlurb]?.value;

  const queueValue = updateQueue[entity.id];
  const isRegenerating = queueValue && !queueValue.resolved;

  const entityProps = { entity, attributes };

  const entityDescription =
    entity.type === EntityType.Company ? companyBrandedBlurb
    : entity.description?.startsWith(NO_DESCRIPTION) ? undefined
    : entity.description;
  return (
    <ProfileBubble className="flex flex-col gap-2" section={ProfilePageSection.Highlights}>
      <div className="flex">
        <div className="flex gap-4 items-center flex-1">
          <ProfileEntityImage entity={entity} />
          <div className="flex-1">
            <div className="flex">
              <h1 className="text-2xl font-semibold flex-1 mr-2">{entity.name}</h1>
              <div className="flex text-gray-400 flex-row gap-1 flex-wrap sm:justify-end items-center">
                <ProfilePopoverMenu
                  entity={entity}
                  isRegenerating={isRegenerating}
                  status={status}
                />
              </div>
            </div>
            {entity.type === EntityType.Person ?
              <PersonSubtitle skipLastUpdated {...entityProps} />
            : <CompanySubtitle skipLastUpdated {...entityProps} />}
          </div>
        </div>
      </div>

      <div className="text-sm">{entityDescription}</div>

      <EntityHighlights />
    </ProfileBubble>
  );
});

function ProfileEntityImage(props: { entity: Entity }) {
  const [open, setOpen] = useState(false);

  const isAdmin = useStore(uiStore.user)?.email?.includes("@distill.fyi");

  return (
    <>
      {isAdmin && <EntityImageEditModal open={open} setOpen={setOpen} />}
      <div
        className={twJoin("h-16 w-16 overflow-hidden c", isAdmin && "cursor-pointer")}
        onClick={isAdmin ? () => setOpen(true) : undefined}
      >
        <EntityIconWithPlaceholder className="h-16 w-16" imageClassName="h-16 w-16" {...props} />
      </div>
    </>
  );
}
