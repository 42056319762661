/* eslint-disable @next/next/no-img-element */
import Modal, { ModalBody, ModalButton } from "@/components/modals/Modal";
import { entityStore } from "@/stores/entityStore";
import { IdentificationIcon } from "@heroicons/react/24/solid";
import { useStore } from "@nanostores/react";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

const EntityImageEditModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (x: boolean) => void;
}) => {
  const entity = useStore(entityStore.entity);
  const [value, setValue] = useState(entity.imageUrl || undefined);
  const [customLink, setCustomLink] = useState<string>("");
  const images = useStore(entityStore.images);
  const invalidImages: (string | null)[] = [];
  const [numItems, setNumItems] = useState(8);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async () => {
    try {
      if (value) {
        await entityStore.updateEntityImage(value);
      } else {
        setError("Please select an image");
        return;
      }
      setOpen(false);
    } catch (error) {
      setError(String(error));
    }
  };

  const loadMore = () => {
    setNumItems((num) => num + 8);
  };

  const validImages = images.filter((i) => (i.src && !invalidImages.includes(i.src)) || false);

  const isExpandable = validImages.length >= numItems;
  const buttons = (
    <>
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <ModalButton className={"bg-blue-600 hover:bg-blue-800 sm:m-0"} onClick={onSubmit}>
        Save
      </ModalButton>
      <ModalButton
        onClick={(e) => {
          e.preventDefault();
          setOpen(false);
        }}
        className="bg-gray-600 hover:bg-gray-800 sm:m-0"
      >
        Cancel
      </ModalButton>
    </>
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <ModalBody
        title="Select an image"
        buttons={buttons}
        buttonsClassName="flex gap-2 flex-col sm:flex-row"
      >
        <div className="flex flex-wrap gap-2">
          <input className="hidden" value={value} />
          {validImages
            .map((i) => {
              if (i.src) {
                return (
                  <button
                    key={i.src}
                    type="button"
                    onClick={() => {
                      setError(null);
                      setValue(i.src || undefined);
                    }}
                  >
                    <img
                      alt="image"
                      src={i.src}
                      onError={() => {
                        invalidImages.push(i.src);
                      }}
                      className={twJoin(
                        "w-20 h-20 object-cover rounded-md hover:border-4 border-brand-600 border-opacity-50",
                        i.src === value && "border-4",
                      )}
                    />
                  </button>
                );
              }
            })
            .slice(0, numItems)}
          {isExpandable && (
            <div className="w-full p-4 flex justify-center">
              <button type="button" onClick={loadMore} className="text-brand-600 font-semibold">
                Load more...
              </button>
            </div>
          )}
          {validImages.length === 0 && (
            <div className="w-full flex justify-center p-4 text-gray-400">
              <p>No images found</p>
            </div>
          )}
          <div className="w-full gap-2 flex flex-col">
            <p>Or paste a link to an image below</p>
            <span className="rounded-full border-none bg-gray-200 w-full flex flex-row overflow-hidden align-middle gap-2 p-2">
              <IdentificationIcon className="text-gray-400 h-6 w-6" />
              <input
                className="bg-transparent border-none focus:ring-0 p-0 w-full"
                onChange={(e) => {
                  setCustomLink(e.target.value);
                }}
              />
            </span>
            {customLink.length > 0 && (
              <div className="w-full flex flex-col justify-center align-middle items-center p-4 gap-4">
                <img
                  className={twJoin(
                    "h-20 w-20 object-cover rounded-md",
                    value === customLink && "border-4 border-brand-600 border-opacity-50",
                  )}
                  alt="Invalid source"
                  src={customLink}
                />
                <button
                  className="bg-blue-600 hover:bg-blue-800 rounded-md p-2 text-white"
                  type="button"
                  onClick={() => {
                    setValue(customLink);
                    setError(null);
                  }}
                >
                  Select image
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EntityImageEditModal;
